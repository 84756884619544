import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"
import { Button, Col, Dropdown, Row } from "react-bootstrap"

interface BlogNavProps {
  backButton?: any
}
export default ({ backButton }: BlogNavProps) => {
  const data = useStaticQuery(graphql`
    query BlogNavigation {
      allContentfulCategory {
        nodes {
          id
          name
          slug
        }
      }
      allContentfulTag {
        nodes {
          id
          name
          slug
        }
      }
    }
  `)
  const { nodes: categories } = data.allContentfulCategory
  const { nodes: tags } = data.allContentfulTag

  return (
    <div className="fixed-top-panel fl-wrap float-none">
      <Row noGutters>
        <div className="scroll-down-wrap hide_onmobile">
          <div className="mousey">
            <div className="scroller" />
          </div>
          <span>Scroll down to discover</span>
        </div>
        <Col className="d-flex flex-row justify-content-end align-items-center">
          {/*<div className="category-filter blog-btn-filter float-none">
            <div className="blog-btn">
              Categories <i className="fa fa-list-ul" aria-hidden="true" />
            </div>
            <ul>
              {categories.map(category => (
                <li key={category.id}>
                  <Link to={`/blog/categories/${category.slug}`}>
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>*/}

          {/*<div className="tag-filter blog-btn-filter float-none">
            <div className="blog-btn">
              Tags <i className="fa fa-tags" aria-hidden="true" />
            </div>
            <ul>
              {tags.map(tag => (
                <li key={tag.id}>
                  <Link to={`/blog/tags/${tag.slug}`}>{tag.name}</Link>
                </li>
              ))}
            </ul>
          </div>*/}

          <Dropdown className="category-filter blog-btn-filter">
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className="border-0"
            >
              Categories{" "}
              <i className="fa fa-list-ul text-primary" aria-hidden="true" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {categories.map(category => (
                <Dropdown.Item
                  key={category.id}
                  as={Link}
                  to={`/blog/categories/${category.slug}`}
                >
                  {category.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="tag-filter blog-btn-filter">
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className="border-0"
            >
              Tags <i className="fa fa-tags text-primary" aria-hidden="true" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {tags.map(tag => (
                <Dropdown.Item
                  key={tag.id}
                  as={Link}
                  to={`/blog/tags/${tag.slug}`}
                >
                  {tag.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/*<Button
            size="sm"
            variant="secondary"
            className="search-btn float-none"
          >
            <i className="fal fa-search" />
            <span>Search</span>
          </Button>*/}

          {backButton}
        </Col>
      </Row>
      <div className="sp-fix-header fl-wrap"></div>
      <div className="blog-search-wrap">
        <input
          name="se"
          id="se"
          type="text"
          className="search"
          placeholder="Type and click Enter to search.."
          value=""
        />
      </div>
    </div>
  )
}
